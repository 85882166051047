<template>
  <div id="top" v-loading.fullscreen.lock="isLoading">
    <div class="header-container" v-if="!isLoading">
      <div v-show="isExpand" class="logo-container flex-center">
        <img class="logo" :src="userInfo.projectLogo" />
      </div>
      <div v-show="!isExpand" class="logo-container flex-center expand">
        <img class="logo" :src="userInfo.projectLogo" />
      </div>
      <!--  <i
          v-show="isExpand"
          class="iconfont icon-a-jianqu45"
          @click="expandChange"
        ></i>
        <i
          v-show="!isExpand"
          class="iconfont icon-a-jianqu46"
          @click="expandChange"
        ></i> -->
      <el-menu :default-active="activePath" mode="horizontal" :router="true">
        <el-menu-item
          :index="item.path"
          v-for="(item, index) in menus"
          :key="index"
          >{{ item.title }}</el-menu-item
        >
      </el-menu>
      <div class="header-right">
        <!-- <el-dropdown class="flex-center">
          <span class="el-dropdown-link">
            <i class="iconfont icon-a-zu4784"> </i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="(item, index) in skinData"
                @click="checkSkin(item)"
                :class="item.id === activeTheme ? 'active-skin' : ''"
                :key="index"
              >
                {{ item.name }}
                <span v-if="item.id === activeTheme">(当前)</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown> -->
        <span class="text-hidden">{{ userInfo.realname }}</span>
        <div class="text-hidden">{{ userInfo.posname }}</div>

        <div>
          <el-button type="success" plain  @click="onLoginMerchant" class="login-merchant">登录商户端</el-button>
        </div>

        <i class="iconfont icon-a-zu4766" @click="handleOut" title="安全退出"></i>
      </div>
    </div>
    <!-- 确认订单弹窗 -->
    <confirm-dialog ref="showDialog" @wConfirm="exit" title="确认要退出系统吗？"></confirm-dialog>
  </div>
</template>

<script>
  import { ref, computed, watch, onMounted } from "vue";
  import { useStore } from "vuex";
  import { useRouter, useRoute } from "vue-router";
  import cookie from "@/utils/cookie";
  import { ElMessage } from "element-plus";
  import { FrameApi } from "@/plugins/api.js";
  import ConfirmDialog from "@/components/confirm-dialog/confirm-dialog.vue";

  export default {
    components: {
      ConfirmDialog,
    },
    setup() {
      const router = useRouter();
      const route = useRoute();
      const store = useStore();
      /** 用户信息 */
      const userInfo = {
        realname: cookie.getCookie("user_name"),
        posname: cookie.getCookie("duty_name"),
        projectLogo:
          cookie.getCookie("project-logo") ||
          "https://f.yunchi.tech/ev/source/sys_auth_title_logo.png",
      };

      /** 是否正在加载数据 */
      const isLoading = ref(false);

      /** 是否展开 */
      const isExpand = computed(() => store.state.Config.isExpand);

      /** 顶部 菜单导航列表 */
      const menus = computed(() => store.state.menuToken.menus);

      /** 当前选择的菜单 */
      const activePath = ref("1");

      /** 是否显示退出系统弹框 */
      const showDialog = ref(null);

      /** 点击退出登录处理 */
      const handleOut = () => {
        showDialog.value.show();
      };

      /** 退出系统确认处理 */
      const exit = () => {
        FrameApi.logout().then((res) => {
          if (res.Code === 200) {
            // 清除cookie
            cookie.delCookie("user_name");
            cookie.delCookie("user_tel");
            cookie.delCookie("user_sex");
            cookie.delCookie("duty_name");
            cookie.delCookie("project-logo");
            router.push("/login");
            store.commit("setIsExpand", true);
            store.commit("menuTokenInit");
          } else {
            ElMessage.error(res.Message);
          }
        });
      };

      /** 换肤 */
      const skinData = ref([
        {
          name: "夏雨蓝",
          id: "blue",
        },
        {
          name: "薄雾黑",
          id: "black",
        },
        {
          name: "暖秋金",
          id: "gold",
        },
        {
          name: "春木绿",
          id: "green",
        },
      ]);

      /** 当前主题 */
      const activeTheme = computed(() => store.state.Config.activeTheme);

      /** 主题切换 */
      const checkSkin = (item) => {
        store.commit("setActiveTheme", item.id);
      };

      /** 路由改变处理 */
      const routeChange = (value, oldValue) => {
        if (oldValue.startsWith(value)) {
          router.push(oldValue);
        } else {
          if (!value || value === "/") {
            activePath.value = menus.value[1].path;
          } else {
            activePath.value = "/" + value.split("/")[1];
          }
        }
        let index = menus.value.findIndex(
          (item) => item.path === activePath.value
        );
        store.commit("setModuleIndex", index === -1 ? 0 : index);
      };

      /** 展开 收起 */
      const expandChange = () => {
        store.commit("setIsExpand", !isExpand.value);
      };

      /** 获取模块权限 */
      const getUserRole = () => {
        isLoading.value = true;
        FrameApi.getUserRole().then((res) => {
          if (res.Code === 200) {
            store.commit("setModuleTokens", res.Data.module_range_list);
            store.commit("setModuleMenus");
            let index = menus.value.findIndex(
              (item) => item.path === activePath.value
            );
            store.commit("setModuleIndex", index === -1 ? 0 : index);
          } else {
            const msg = res.Message ? res.Message : "获取模块权限失败！";
            ElMessage.error(msg);
          }
          isLoading.value = false;
        });
      };
      /**
       * 
       * 一键登录商户端
       * 
       * */
      const onLoginMerchant = () => {
        isLoading.value = true;
        FrameApi.oneClickLoginMerchant().then((res) => {
          if (res.Code === 200) {
            // window.open('https://ev.yunchi.tech/web/merchant/#/oneClickLogin','_blank');  // 正式
            window.open('https://evt.yunchi.tech/web/merchant/#/oneClickLogin','_blank'); // 测试
          } else {
            ElMessage.error("登录失败!");
          }
          isLoading.value = false;
        });
      }

      /** 监听路由改变 */
      watch(() => route.path, routeChange);

      onMounted(() => {
        // 获取用户模块权限
        userInfo.realname && getUserRole();
      });

      return {
        isLoading,
        isExpand,
        userInfo,
        menus,
        activePath,
        showDialog,
        handleOut,
        exit,
        skinData,
        activeTheme,
        checkSkin,
        expandChange,
        store,
        onLoginMerchant,
      };
    },
  };
</script>

<style lang="scss">
  #top {
    .el-dialog .el-dialog__footer .el-button {
      width: 160px;
    }

    .logo-container {
      min-width: 250px;
      padding: 0;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;

      .logo {
        width: 60px;
      }
    }

    .login-merchant {
      min-height: 20px;
      width: 100px;
      padding: 4px 0;
    }
    .logo-container.expand {
      min-width: 150px;
    }

    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      >.iconfont {
        font-size: 32px;
        color: var(--theme-color);
        margin-right: 15px;
        cursor: pointer;
      }

      .el-dropdown__popper {
        --el-dropdown-menu-box-shadow: var(--search-bg-color) !important;
        --el-dropdown-menuItem-hover-fill: var(--search-bg-color) !important;
        --el-dropdown-menuItem-hover-color: var(--search-bg-color) !important;
        --el-dropdown-menu-index: 10;
      }

      .active-skin {
        color: var(--theme-color) !important;
        background-color: var(--search-bg-color) !important;
      }

      .el-menu--horizontal>.el-menu-item.is-active {
        border-bottom: 2px solid var(--theme-color);
        color: var(--theme-color) !important;
        background-color: var(--search-bg-color);
      }

      .header-right {
        justify-content: flex-end;
        padding: 0 30px 0 20px;
        color: var(--text-third-color);
        font-size: 15px;
        display: flex;

        >span {
          text-align: right;
        }

        >div {
          margin: 0 26px 0 14px;
        }

        i {
          cursor: pointer;

          &:hover {
            font-weight: bold;
          }
        }
      }

      .el-menu {
        flex: 1;
        width: calc(100% - 590px);
        border-bottom: none;

        .el-menu-item.is-active {
          font-weight: bold;
          pointer-events: none;
          cursor: pointer;
        }
      }
    }

    .el-header {
      height: auto;
      line-height: 80px;
      padding: 0px;

      .el-menu--horizontal>.el-menu-item {
        height: 80px;
      }

      .el-menu--horizontal {
        border: none;
      }
    }

    .child-nav {
      padding: 15px 20px 0 20px;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      overflow: -moz-scrollbars-none;
      background-color: var(--theme-bg-color);

      .el-button {
        min-height: 32px !important;
        height: 32px;
        line-height: 0px;
      }

      .child-btn {
        background-color: var(--theme-bg-color);
        color: var(--theme-color);
        border-color: var(--theme-color);
      }

      .active-btn {
        color: #ffffff;
        background-color: var(--theme-color);
        outline: 0;
      }
    }

    .child-nav::-webkit-scrollbar {
      display: none;
    }
  }
</style>