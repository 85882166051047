// 新能源-电站管理-子路径配置

const electricityChilds = [
  // 电站管理
  {
    path: "powerStation",
    name: "PowerStation",
    component: () => import("@/views/electricity/powerStation.vue"),
    children: [
      // 电站管理
      {
        path: "powerManage",
        name: "PowerManage",
        component: () => import("@/views/electricity/children/powerManage.vue"),
      },
      // 收费配置
      {
        path: "chargingConfig",
        name: "ChargingConfig",
        component: () => import("@/views/electricity/children/chargingConfig.vue"),
      },
      // 充电桩管理
      {
        path: "electricPile",
        name: "ElectricPile",
        component: () => import("@/views/electricity/children/electricPile.vue"),
      },
      // 设备监控
      {
        path: "monitEquip",
        name: "MonitEquip",
        component: () => import("@/views/electricity/children/monitEquip.vue"),
      },
      // 私桩管理
      {
        path: "privateStake",
        name: "PrivateStake",
        component: () => import("@/views/electricity/children/privateStake.vue"),
      }
    ],
  },
  // 订单管理
  {
    path: "orderManage",
    name: "OrderManage",
    component: () => import("@/views/order/orderManage.vue"),
    children: [
      // 充电订单
      {
        path: "chargingOrder",
        name: "ChargingOrder",
        component: () => import("@/views/order/children/chargingOrder.vue"),
      },
      // 预存订单
      {
        path: "prestoreOrder",
        name: "PrestoreOrder",
        component: () => import("@/views/order/children/prestoreOrder.vue"),
      },
      // 开票申请
      {
        path: "invoicing",
        name: "Invoicing",
        component: () => import("@/views/order/children/invoicing.vue"),
      },
      // 会员月卡
      {
        path: "monthCard",
        name: "MonthCard",
        component: () => import("@/views/order/children/monthCard.vue"),
      },
      // 续费订单
      {
        path: "renewOrder",
        name: "RenewOrder",
        component: () => import("@/views/order/children/renewOrder.vue"),
      },
    ],
  },
  //会员管理
  {
    path: "memberManage",
    name: "MemberManage",
    component: () => import("@/views/member/memberManage.vue"),
    children: [
      {
        path: "member",
        name: "Member",
        component: () => import("@/views/member/children/member.vue"),
      },
      {
        path: "monthlyCard",
        name: "MonthlyCard",
        component: () => import("@/views/member/children/memberMonthlyCard.vue"),
      },
    ]
  },
  //优惠管理
  {
    path: "couponsManage",
    name: "CouponsManage",
    component: () => import("@/views/coupons/couponsManage.vue"),
    children: [{
      path: "coupons",
      name: "Coupons",
      component: () => import("@/views/coupons/children/coupons.vue"),
    }]
  },
  //通知公告
  {
    path: "notificaAnnounc",
    name: "NotificaAnnounc",
    component: () => import("@/views/notice/notificaAnnounc.vue"),
    children: [{
      path: "notice",
      name: "Notice",
      component: () => import("@/views/notice/children/notice.vue"),
    }]
  },
  //活动管理
  {
    path: "activity",
    name: "Activity",
    component: () => import("@/views/activity/index.vue"),
    children: [{
      path: "activityManage",
      name: "ActivityManage",
      component: () => import("@/views/activity/children/activityManage.vue"),
    }]
  },
  //积分管理
  {
    path: "points",
    name: "Points",
    component: () => import("@/views/points/index.vue"),
    children: [
      {
        path: "pointsManage",
        name: "PointsManage",
        component: () => import("@/views/points/children/pointsManage.vue"),
      },
      {
        path: "pointsOrder",
        name: "PointsOrder",
        component: () => import("@/views/points/children/pointsOrder.vue"),
      },
    ]
  },
  // 首页轮播
  {
    path: "carousel",
    name: "Carousel",
    component: () => import("@/views/carousel/index.vue"),
    children: [
      {
        path: "homeCarousel",
        name: "HomeCarousel",
        component: () => import("@/views/carousel/children/homeCarousel.vue"),
      },
    ]
  }
];
export default electricityChilds;
