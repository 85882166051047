<script setup>
	import {
		computed,
		onMounted,
		ref,
		watch,
	} from "vue";
	import {
		useRouter,
		useRoute
	} from "vue-router";
	import {
		useStore
	} from "vuex";

	const store = useStore();
	const router = useRouter();

	/** 当前路由 path */
	const path = useRoute().path;

	/** 按钮列表 */
	const btnList = computed(() => store.getters.btnMenus);
	watch(btnList, () => {
		setRoute();
	});

	/** 默认路由 path */
	const activeName = ref("");

	/** 设置路由 */
	const setRoute = () => {
		/** 路由列表 */
		const paths = btnList.value.map((item) => item.path);
		if (paths.length > 0) {
			if (paths.includes(path)) {
				activeName.value = path;
			} else {
				activeName.value = paths[0];
				router.push(paths[0]);
			}
		}
	};

	/** 路由 btn 点击处理 */
	const tabClick = (obj) => {
		router.push(obj.paneName);
	};

	onMounted(() => {
		setRoute();
	});
</script>

<template>
	<div class="button-router">

		<el-tabs v-model="activeName" @tab-click="tabClick">
			<el-tab-pane v-for="item in btnList" :key="item.id" :name="item.path">
				<template #label>
					<span class="btn">{{ item.title }}</span>
				</template>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<style lang="scss">
	.button-router {
		width: calc(100vw - 250px);
		height: 60px;

		.el-tabs {
			border: none;
			box-shadow: none;
			padding: 14px 25px;
			background-color: transparent;

			.el-tabs__header {
				border: none;
				margin: 0;

				.el-tabs__item {
					padding: 0 15px 0 0 !important;
					height: 34px;
					line-height: 34px;

					.btn {
						display: inline-block;
						width: 110px;
						height: 34px;
						line-height: 34px;
						text-align: center;
						border-radius: 17px;
						background-color: var(--search-bg-color);
						color: var(--theme-color);
					}
				}

				.el-tabs__item.is-active {
					padding: 0;
					border: none;
					padding: 0 10px 0 0;
					background-color: transparent;

					.btn {
						background-color: var(--theme-color);
						color: #fff;
					}
				}

				.el-tabs__item:last-child {
					padding: 0 !important;
				}

				.el-tabs__nav-wrap {

					.el-tabs__nav-prev,
					.el-tabs__nav-next {
						line-height: 34px;
						font-size: 16px;
						box-sizing: border-box;

						.el-icon {
							margin-top: 10px;
						}
					}

					.el-tabs__nav-scroll {
						.el-tabs__nav {
							.el-tabs__active-bar {
								display: none;
							}
						}
					}
				}

				.el-tabs__nav-wrap::after {
					display: none;
				}
			}

			.el-tabs__content {
				display: none;
			}
		}
	}
</style>