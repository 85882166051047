import {
	createRouter,
	createWebHashHistory
} from "vue-router";
import cookie from "../utils/cookie";
// 子级路由
import basicChilds from "./module/basic-childs.js";
import electricityChilds from "./module/electricity-childs.js";

const routes = [{
		path: "/",
		name: "Index",
		redirect: "/home",
	},
	{
		path: "/home",
		name: "Home",
		component: () => import("@/views/home/index.vue")
	},
	{
		path: "/login",
		name: "Login",
		component: () => import("@/views/login/index.vue"),
	},
	{
		path: "/init",
		name: "Init",
		component: () => import("@/views/login/init.vue"),
	},
	{
		path: "/oauth",
		name: "Oauth",
		component: () => import("@/views/login/oauth.vue"),
	},

	{
		path: "/basic",
		name: "Basic",
		component: () => import("@/views/basic/index.vue"),
		children: basicChilds,
	},
	{
		path: "/electricity",
		name: "Electricity",
		component: () => import("@/views/electricity/index.vue"),
		children: electricityChilds,
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	const isLogin = cookie.getCookie("user_name");
	if (!isLogin) {
		if (to.path !== "/login" && to.path !== "/init" && to.path !== "/oauth") {
			next("/login");
		} else {
			next();
		}
	} else {
		next();
	}

	window.document.title = "云驰新能源管理";
});

router.afterEach(() => {
	window.scrollTo(0, 0);
});

export default router;