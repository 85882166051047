<template>
	<el-aside id="left-nav" :width="isExpand ? '250px' : '150px'" v-loading="isLoading">
		<div>
			<el-dropdown>
				<div class="select-container">
					<!-- <img src="@/assets/img/succes.png" style="width: 50px;height: 50px;" /> -->
					<div>{{ userInfo.realname }}</div>
				</div>
				<template #dropdown>
					<el-dropdown-menu>
						<el-dropdown-item @click="showUpdatePwd">修改密码</el-dropdown-item>
						<el-dropdown-item @click="handleOut">退出登录</el-dropdown-item>
					</el-dropdown-menu>
				</template>
			</el-dropdown>

			<div class="menu-wp" v-if="!isLoading && navMenus.length > 0">
				<div :class="['first-wp', activePath === navMenus[0].path ? 'active' : '']">
					<div class="first"></div>
				</div>
				<el-menu :default-active="activePath" class="el-menu-vertical-demo" :router="true">
					<el-menu-item v-for="(item, index) in navMenus" :key="index" :index="item.path" :class="{
              active: activePath === item.path,
              before:
                index + 1 < navMenus.length &&
                navMenus[index + 1].path === activePath,
              after: index > 0 && navMenus[index - 1].path === activePath,
            }">
						<template #title>
							<div class="nav-item" v-show="!isExpand">
								<el-tooltip class="box-item" effect="dark" :content="item.title" placement="right">
									<i :class="['iconfont', item.icon]"></i>
								</el-tooltip>
							</div>
							<div class="nav-item" v-show="isExpand">
								<i :class="['iconfont', item.icon]"></i>
								<div class="name">{{ item.title }}</div>
							</div>
						</template>
					</el-menu-item>
				</el-menu>
				<div :class="[
            'last-wp',
            activePath === navMenus[navMenus.length - 1].path ? 'active' : '',
          ]">
					<div class="last"></div>
				</div>
			</div>
		</div>

		<w-dialog ref="resetPasswordDialog" title="修改密码" width="30%" top="20vh" @wConfirm="handleResetPassword">
			<el-form ref="resetPasswordFormRef" :model="resetPasswordFormData" :rules="resetPasswordFormRules"
				label-position="top" class="organization-form">
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item prop="old_pwds" label="旧密码">
							<el-input v-model="resetPasswordFormData.old_pwds" autocomplete="off" auto-complete="new-password" placeholder="请输入旧密码"
								clearable />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item prop="new_pwds" label="新密码">
							<el-input v-model="resetPasswordFormData.new_pwds" autocomplete="off" auto-complete="new-password" placeholder="请输入新密码"
								clearable show-password />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item prop="new_pwd2" label="确认密码">
							<el-input v-model="resetPasswordFormData.new_pwd2" autocomplete="off" auto-complete="new-password" placeholder="请再次输入新密码"
								clearable show-password />
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</w-dialog>
	</el-aside>
</template>

<script>
	import {
		ref,
		watch,
		computed
	} from "vue";
	import {
		useRouter,
		useRoute
	} from "vue-router";
	import {
		useStore
	} from "vuex";
	import cookie from "@/utils/cookie";
	import {
		ElMessage
	} from "element-plus";
	import {
		FrameApi
	} from "@/plugins/api.js";
	import {
		checkStr
	} from "@/utils/common.js";

	export default {
		components: {},
		setup() {
			
			const showUpdatePwd = () =>{
				resetPasswordDialog.value.show()
			}
			
			const router = useRouter();
			const route = useRoute();
			const store = useStore();
			const menuToken = store.state.menuToken;
			const isExpand = computed(() => store.state.Config.isExpand);

			/** 是否正在加载菜单 */
			const isLoading = ref(false);
			/** 当前模块 index */
			const moduleIndex = computed(() => store.state.menuToken.moduleIndex);
			/** 左侧菜单导航 */
			const navMenus = computed(() => {
				return menuToken.moduleIndex !== "" ?
					menuToken.menus[menuToken.moduleIndex].childRoute : [];
			});
			/** 监听 模块 index 改变 */
			watch(moduleIndex, () => {
				if (moduleIndex.value !== "") {
					// 获取用户菜单/按钮权限
					userInfo.realname && getUserRole();
				}
			});

			/** 获取菜单/按钮权限 */
			const getUserRole = () => {
				isLoading.value = true;
				let data = {
					module_token: menuToken.menus[menuToken.moduleIndex].token,
				};
				FrameApi.getUserRole(data).then((res) => {
					if (res.Code === 200) {
						if (menuToken.moduleIndex == 2 && data.module_token ==
							'm_7oaDDI1u1TKrU9JUeQjRishRcfQE6J') {
							let basicArr = ['members', 'permissioGroup', 'permissionList', 'payment',
								'applet', 'supervise',
							];
							if (res.Data.menu_node_list) {
								basicArr.push(...res.Data.menu_node_list)
							}
							// 只有后台管理需要添加
							res.Data.menu_node_list = basicArr;
						}
						store.commit("setMenuTokens", res.Data.menu_node_list);
						store.commit("setBtnMenus");
						/** 设置初始路由 */
						setRouter(route.path);
					} else {
						const msg = res.Message ? res.Message : "获取菜单权限失败！";
						ElMessage.error(msg);
					}
					isLoading.value = false;
				});
			};

			/** 默认激活菜单 path */
			let activePath = ref("");

			/** 用户信息 */
			const userInfo = {
				realname: cookie.getCookie("user_name"),
				posname: cookie.getCookie("duty_name"),
			};

			/** 设置路由 */
			const setRouter = (value) => {
				if (navMenus.value.length > 0) {
					const paths = navMenus.value.map((item) => item.path);
					const path = paths.find((item) => value.startsWith(item));
					if (path) {
						activePath.value = path;
					} else if (paths.length > 0 && paths[0].startsWith(value)) {
						activePath.value = paths[0];
						router.push(paths[0]);
					}
					const index = navMenus.value.findIndex(
						(item) => item.path === activePath.value
					);
					store.commit("setMenuIndex", index);
				}
			};

			function handleOut() {
				FrameApi.logout().then((res) => {
					if (res.Code === 200) {
						// 清除cookie
						cookie.delCookie("user_name");
						cookie.delCookie("user_tel");
						cookie.delCookie("user_sex");
						cookie.delCookie("duty_name");
						cookie.delCookie("project-logo");
						router.push("/login");
						store.commit("setIsExpand", true);
						store.commit("menuTokenInit");
					} else {
						ElMessage.error(res.Message);
					}
				});
			}
			const resetPasswordDialog = ref(null);
			const resetPasswordFormRef = ref(null);
			const resetPasswordFormData = ref({
				old_pwds: "",
				new_pwds: "",
				new_pwd2: "",
			});

			const resetPasswordFormRules = {
				old_pwds: [{
					required: true,
					message: "请输入旧密码",
					trigger: "blur",
				}, ],
				new_pwds: [{
					required: true,
					message: "请输入新密码",
					trigger: "blur",
				}, ],
				new_pwd2: [{
					required: true,
					message: "请再次输入新密码",
					trigger: "blur",
				}, ],
			};

			function handleResetPassword() {
				if (resetPasswordFormRef.value) {
					resetPasswordFormRef.value.validate((valid) => {
						if (valid) {
							if (
								resetPasswordFormData.value.new_pwds !=
								resetPasswordFormData.value.new_pwd2
							) {
								ElMessage.error({
									message: "两次密码不一致！",
								});
								return false;
							}
							if (!checkStr(resetPasswordFormData.value.new_pwds, "pwd2")) {
								ElMessage.warning("新密码必须包含字母和数字，且长度大于等于6！");
								return false;
							}
							FrameApi.editPassword({
								old_password: resetPasswordFormData.value.old_pwds,
								new_password: resetPasswordFormData.value.new_pwds,
							}).then((res) => {
								if (res.Code ==200) {
									ElMessage.success({
										message: "修改成功，请重新登录！",
									});
									resetPasswordDialog.value.close();
									setTimeout(() => {
										handleOut();
									}, 600);
								}else{
									ElMessage.success({
										message: res.Message
									});
								}
							});
						}
					});
				}
			}
			/** 监听路由改变 */
			watch(() => route.path, setRouter);
			return {
				showUpdatePwd,
				handleOut,
				isLoading,
				userInfo,
				navMenus,
				activePath,
				isExpand,
				resetPasswordDialog,
				handleResetPassword,
				resetPasswordFormRef,
				resetPasswordFormData,
				resetPasswordFormRules,
			};
		},
	};
</script>

<style lang="scss">
	.el-aside {
		background-color: #fff;
		color: var(--el-text-color-primary);
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.select-container {
			margin: 20px 0 0 0;
			padding: 0 16px;
			width: 100%;
		}

		.menu-wp {
			.first-wp {
				background-color: #fff;

				.first {
					height: 30px;
					border-radius: 0 0 30px 0;
					background-color: #fff;
				}
			}

			.first-wp.active {
				background-color: var(--theme-bg-color);
			}

			.last-wp {
				background-color: #fff;

				.last {
					height: 30px;
					border-radius: 0 30px 0 0;
					background-color: #fff;
				}
			}

			.last-wp.active {
				background-color: var(--theme-bg-color);
			}

			.el-menu {
				padding-left: 18px;
				border-right: none;

				.el-menu-item {
					padding: 0 !important;

					.nav-item {
						width: 100%;
						height: 100%;
						display: flex;
						padding-left: 40px;
						background-color: #fff;
						color: var(--text-third-color);

						>i {
							margin-right: 12px;
							font-size: 22px;
						}

						.name {
							flex: 1;
							text-align: left;
						}
					}
				}

				.el-menu-item.active {
					color: var(--theme-color);
					font-weight: bold;
					border-radius: 28px 0 0 28px;

					.nav-item {
						border-radius: 28px 0 0 28px;
						color: var(--theme-color);
						background-color: var(--theme-bg-color);
					}
				}

				.el-menu-item.active:hover {
					background-color: #fff;
				}

				.el-menu-item.before {
					background-color: var(--theme-bg-color);

					.nav-item {
						border-radius: 0 0 30px 0;
						background-color: #fff;
					}
				}

				.el-menu-item.after {
					background-color: var(--theme-bg-color);

					.nav-item {
						border-radius: 0 30px 0 0;
						background-color: #fff;
					}
				}
			}
		}
	}
</style>