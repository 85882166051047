// 后台管理-系统管理-子路径配置

const basicChilds = [
	{
		path: "user",
		name: "User",
		component: () => import("@/views/basic/user.vue"),
		children: [
			{
				path: "members",
				name: "Members",
				component: () => import("@/views/basic/children/members.vue"),
			},
			{
				path: "permissioGroup",
				name: "PermissioGroup",
				component: () => import("@/views/basic/children/permissioGroup.vue"),
			},
			{
				path: "permissionList",
				name: "PermissionList",
				component: () => import("@/views/basic/children/permissionList.vue"),
			},
			{
				path: "payment",
				name: "Payment",
				component: () => import("@/views/basic/children/payment.vue"),
			},
			{
				path: "applet",
				name: "Applet",
				component: () => import("@/views/basic/children/applet.vue"),
			},
			{
				path: "supervise",
				name: "Supervise",
				component: () => import("@/views/basic/children/supervise.vue"),
			}
		]
	},
];
export default basicChilds;
