import axios from "./axios.js";

/** 外层框架 */
const FrameApi = {
	/** 登录 */
	login(data) {
		return axios.post("/home/login/account.api", data);
	},
	/** 管理端退出登录 */
	logout(data) {
		return axios.post("/home/home/logout.api", data);
	},
	/** 获取登录页面信息 */
	getSystemInfo(data) {
		return axios.post("/home/baseinfo/get_system_info.api", data);
	},
	/** 获取当前用户信息 */
	getUserInfo(data) {
		return axios.post("/home/home/get_user_info.api", data);
	},
	/** 获取用户权限信息 */
	getUserRole(data) {
		return axios.post("/home/home/get_user_node.api", data);
	},
	/** 编辑密码 */
	editPassword(data) {
		return axios.post("/home/home/change_password", data);
	},
	qywxInfo(data) {
		return axios.post("/home/qy_login/get_login_before.api", data);
	},
	getUserInfoByqy(data) {
		return axios.post("/home/qy_login/auth_login.api", data);
	},
	qySdk(data) {
		return axios.post("/home/qy_login/get_qy_jssdk.api", data);
	},
	geQiniuToken(data) {
		return axios.post("/home/home/qyn_up_token.api", data);
	},
	/** 一键登录商户端 */
	oneClickLoginMerchant(data) {
		return axios.post("/home/home/login_merchant.api", data);
	},


};

// 电站管理
const PowerStations = {
	/* 电站管理 */
	/**
	 * equipment
	 * @param {String} s_id 充电站id
	 */
	getPowerCommonEquipmentList(data) {
		return axios.post("/charge/common/equipment_list", data);
	},
	// 电站列表
	getPowerCommonStationList(data) {
		return axios.post("/charge/common/station_list", data);
	},
	// 电站列表
	getPowerStationLists(data) {
		return axios.post("/charge/station/lists", data);
	},
	// 电站详情
	getPowerStationInfo(data) {
		return axios.post("/charge/station/info", data);
	},
	// 电站图片获取
	getStationPicture(data) {
		return axios.post("/charge/station/get_pic", data);
	},
	// 电站图片编辑
	editStationPicture(data) {
		return axios.post("/charge/station/edit_pic", data);
	},
	// 电站编辑
	editStation(data) {
		return axios.post("/charge/station/edit", data);
	},

	//电站关联商户号列表
	stationMchPayConfigList(data) {
		return axios.post("/charge/common/mch_pay_config_list", data);
	},
	//获取电站编辑页面数据

	stationGetEditInfo(data) {
		return axios.post("/charge/station/get_edit_info", data);
	},



	// 电站图片删除
	delStationPicture(data) {
		return axios.post("/charge/station/del_pic", data);
	},

	/* 充电桩管理 */
	// 充电桩列表
	getEquipmentLists(data) {
		return axios.post("/charge/equipment/lists", data);
	},

	/* 设备监控 */
	// 充电设备列表
	getConnectorLists(data) {
		return axios.post("/charge/connector/lists", data);
	},
	// 充电设备统计
	getConnectorCount(data) {
		return axios.post("/charge/connector/get_count.api", data);
	},

	/* 私桩管理 */
	// 私桩列表
	getPrivateLists(data) {
		return axios.post("/charge/equipment_private/lists", data);
	},
	// 私桩电表类型
	getMetreTypeLists(data) {
		return axios.post("/charge/common/metre_type_list", data);
	},
	// 电站列表
	getEquipmentStation(data) {
		return axios.post("/charge/common/station_list", data);
	},
	// 绑定私人充电桩
	equipmentBind(data) {
		return axios.post("/charge/equipment_private/equipment_bind", data);
	},
	// 解绑私人充电桩
	equipmentUnbind(data) {
		return axios.post("/charge/equipment_private/equipment_unbind", data);
	},

}
// 系统管理
const SystemManage = {
	/* 公共 */
	// 用户性别
	getUserSex(data) {
		return axios.post("/manage/common/user_sex", data);
	},
	// 用户状态
	getUserStatus(data) {
		return axios.post("/manage/common/user_status", data);
	},
	// 用户类型
	getUserType(data) {
		return axios.post("/manage/common/user_type", data);
	},
	// 模块列表
	getModuleLists(data) {
		return axios.post("/manage/common/module_list", data);
	},
	// 模块-资源列表
	getModuleRangeLists(data) {
		return axios.post("/manage/common/module_range_list", data);
	},
	// 模块-资源列表
	getTopNodeLists(data) {
		return axios.post("/manage/common/top_node_list", data);
	},


	/* 用户管理 */
	// 用户列表
	getUserLists(data) {
		return axios.post("/manage/user/lists", data);
	},
	// 新增用户
	addUsers(data) {
		return axios.post("/manage/user/add", data);
	},
	// 状态
	modifyUsersStatus(data) {
		return axios.post("/manage/user/status", data);
	},
	// 分组列表
	getGroupLists(data) {
		return axios.post("/manage/common/group_list", data);
	},
	// 模块-资源范围更新
	allocationModuleRange(data) {
		return axios.post("/manage/user/module_range", data);
	},
	// 权限分组更新
	allocationGroup(data) {
		return axios.post("/manage/user/group", data);
	},
	// 密码重置
	resetPasswd(data) {
		return axios.post("/manage/user/passwd", data);
	},

	/* 权限分组 */
	// 权限列表
	getPermissionGroupLists(data) {
		return axios.post("/manage/node_group/lists", data);
	},
	// 新增
	addNodeGroup(data) {
		return axios.post("/manage/node_group/add", data);
	},
	// 编辑
	editNodeGroup(data) {
		return axios.post("/manage/node_group/edit", data);
	},
	// 分组用户列表
	getNodeGroupUser(data) {
		return axios.post("/manage/node_group/user", data);
	},
	// 分组用户移除
	deleteNodeGroupUser(data) {
		return axios.post("/manage/node_group/user_delete", data);
	},
	// 删除权限分组
	deleteNodeGroup(data) {
		return axios.post("/manage/node_group/delete", data);
	},
	// 模块列表
	getModuleGroupLists(data) {
		return axios.post("/manage/node_group/module", data);
	},
	// 分组模块下权限列表
	getModuleNode(data) {
		return axios.post("/manage/node_group/module_node", data);
	},
	// 分组模块权限更新
	addModuleNodeSave(data) {
		return axios.post("/manage/node_group/module_node_save", data);
	},

	/* 权限列表 */
	// 权限列表
	getNodeLists(data) {
		return axios.post("/manage/node/lists", data);
	},
	// 新增权限
	addNodeList(data) {
		return axios.post("/manage/node/add", data);
	},

	/* 支付配置 */
	// 列表
	getPayConfigLists(data) {
		return axios.post("/manage/pay_config/lists", data);
	},
	// 小程序列表
	getAppletLists(data) {
		return axios.post("/manage/pay_config/mini_list", data);
	},
	// 绑定小程序
	bindApplet(data) {
		return axios.post("/manage/pay_config/mini_bind", data);
	},
	// 解绑小程序
	unBindApplet(data) {
		return axios.post("/manage/pay_config/mini_unbind", data);
	},
	// 电站列表
	getStationLists(data) {
		return axios.post("/manage/pay_config/station_list", data);
	},
	// 绑定电站
	bindStation(data) {
		return axios.post("/manage/pay_config/station_bind", data);
	},
	// 解绑电站
	unBindStation(data) {
		return axios.post("/manage/pay_config/station_unbind", data);
	},
	// 支付管理员
	getManagerList(data) {
		return axios.post("/manage/pay_config/manager_list", data);
	},
	// 绑定管理员
	addManagerBind(data) {
		return axios.post("/manage/pay_config/manager_bind", data);
	},
	// 解绑管理员
	unbindAdmin(data) {
		return axios.post("/manage/pay_config/manager_unbind", data);
	},

	/* 小程序配置 */
	// 列表
	getMiniLists(data) {
		return axios.post("/manage/mini/lists", data);
	},
	// 电站列表
	getMiniStationLists(data) {
		return axios.post("/manage/mini/station_list", data);
	},
	// 绑定电站
	bindMiniStation(data) {
		return axios.post("/manage/mini/station_bind", data);
	},
	// 解绑电站
	unBindMiniStation(data) {
		return axios.post("/manage/mini/station_unbind", data);
	},
	//小程序平台列表
	platformList(data) {
		return axios.post("/charge/common/platform_list", data);
	},
	//小程序详情
	platEditInfo(data) {
		return axios.post("/manage/mini/edit_info", data);
	},
	//小程序获取管理人员
	managerList(data) {
		return axios.post("/manage/common/manager_list", data);
	},
	//商户列表
	mchPayConfigList(data) {
		return axios.post("/manage/common/mch_pay_config_list", data);
	},
	//小程序获取编辑页面
	miniEditInfo(data) {
		return axios.post("/manage/mini/edit_info", data);
	},
	//小程序编辑页面确定
	miniEdit(data) {
		return axios.post("/manage/mini/edit", data);
	},
	//查询符合优惠券条件的指定会员查询列表
	mateMemberLists(data) {
		return axios.post("/charge/common/mate_member_list", data);
	},

	/* 监管配置 */
	// 监管配置列表
	getSuperviseStationLists(data) {
		return axios.post("/manage/station/lists.api", data);
	},
	// 编辑初始化数据
	getEditInit(data) {
		return axios.post("/manage/station/edit_init.api", data);
	},
	// 查询省份城市区县编码
	getAreaCode(data) {
		return axios.post("/manage/station/get_area_code.api", data);
	},
	// 查询行政区域编码
	getAreaCodeCountryside(data) {
		return axios.post("/manage/station/get_area_code_countryside.api", data);
	},
	// 编辑监管
	editSupervise(data) {
		return axios.post("/manage/station/edit.api", data);
	},
	// 监管配置详情
	getSuperviseInfo(data) {
		return axios.post("/manage/station/info.api", data);
	},
}
// 订单管理
const Order = {
	/* 充电订单 */
	// 充电订单列表
	getChargingOrderLists(data) {
		return axios.post("/charge/order/lists", data);
	},
	// 充电订单详情
	getChargingOrderInfo(data) {
		return axios.post("/charge/order/info", data);
	},
	// 上传发票附件
	uploadInvoice(data) {
		return axios.post("/charge/order/invoice", data);
	},
	orderRelated(data) {
		return axios.post("/charge/order/related", data);
	},
	//订单导出
	orderExport(data) {
		return axios.get("/charge/order/export", data);
	},
	//发送邮件
	sendingEmails(data) {
		return axios.post("/charge/order/emails", data);
	},
	// 获取会员月卡订单详情
	getMonthlyCardOrderInfo(data) {
		return axios.post("/charge/order/monthly-card-order-info", data);
	},
	// 获取会员月卡
	getMonthlyCardOption(data) {
		return axios.post("/charge/order/get-monthly-card", data);
	},
}
//会员管理
const Member = {
	//会员列表
	getMemberList(data) {
		return axios.post("/charge/member/lists", data);
	},
	getMemberInfo(data) {
		return axios.post("/charge/member/info", data);
	},
	//解绑
	memberUnbind(data) {
		return axios.post("/charge/member/unbind", data);
	},
	//会员订单明细内获得积分明细
	getIntDetail(data) {
		return axios.post("/charge/member/getintdetail", data);
	},

	/** 会员月卡 **/
	// 获取月卡列表
	getMonthlyCardList(data) {
		return axios.post("/charge/monthlycard/list", data);
	},
	// 获取优惠券列表
	getCouponList(data) {
		return axios.post("/charge/monthlycard/get-coupon-list", data);
	},
	// 新增月卡
	addMonthlyCard(data) {
		return axios.post("/charge/monthlycard/add", data);
	},
	// 修改在售状态
	editMonthlyCardStatus(data) {
		return axios.post("/charge/monthlycard/edit-status", data);
	},
	// 获取月卡详情
	getMonthlyCardInfo(data) {
		return axios.post("/charge/monthlycard/info", data);
	},
	// 获取月卡销售列表
	getMonthlyCardOrder(data) {
		return axios.post("/charge/monthlycard/order", data);
	},
}
/**
 * 优惠券管理
 */
const Coupons = {
	//优惠券列表
	couponsList(data) {
		return axios.post("/charge/coupon/lists", data);
	},
	//添加优惠券
	couponsAdd(data) {
		return axios.post("/charge/coupon/add", data);
	},
	//发券
	couponsDealt(data) {
		return axios.post("/charge/coupon/dealt", data);
	},
	//详情
	couponsInfo(data) {
		return axios.post("/charge/coupon/info", data);
	}
}
// 系统首页
const Home = {
	/**
	 * 系统首页数据汇总
	 * @param  {String} time_range 时间范围
	 * @param  {String} station_range 电站id
	*/
	evDataCount(data) {
		return axios.post("/home/home/ev_data_count", data);
	},
	/**
	 * 查询快充、慢充电桩状态
	 * @param  {String} type 1 快充 2 慢充
	*/
	dcAcFilter(data) {
		return axios.post("/home/home/dc_ac_filter", data);
	},
	/**
	 * 获取故障设备列表
	 * @param {String} station_range 电站id
	*/

	faultList(data) {
		return axios.post("/home/home/fault_list", data);
	},

}
/**
 * 通知公告
 */
const Notification = {
	/**
	 * 公告列表
	*/
	getNoticeLists(data) {
		return axios.post("/charge/notice/lists", data);
	},
	/**
	 * 添加公告
	*/
	addNotice(data) {
		return axios.post("/charge/notice/add", data);
	},
	/**
	 * 编辑通知公告
	*/
	editNotice(data) {
		return axios.post("/charge/notice/edit", data);
	},
	/**
	 * 页面详情
	 * @param {String} type: 1：查询编辑页面数据 2：查询公告关联电站信息
	*/
	getNoticeInfo(data) {
		return axios.post("/charge/notice/info", data);
	},
	/**
	 * 绑定电站
	*/
	bindNotice(data) {
		return axios.post("/charge/notice/bind", data);
	},
}
/**
 * 活动管理
 */
const ActivityManage = {
	/**
	 * 活动管理列表
	*/
	getActivityLists(data) {
		return axios.post("/charge/activity/lists", data);
	},
	/**
	 * 获得活动类型
	*/
	getTypeLists(data) {
		return axios.post("/charge/common/gettypelist", data);
	},
	/**
	 * 获得小程序列表
	*/
	getMiniProgramsLists(data) {
		return axios.post("/charge/common/getminilist", data);
	},
	/**
	 * 获得满足条件的优惠券
	*/
	getCopon(data) {
		return axios.post("/charge/common/getcopon", data);
	},
	/**
	 * 新增活动
	*/
	addActivity(data) {
		return axios.post("/charge/activity/addactivity", data);
	},
	/**
	 * 新增活动
	*/
	setStatus(data) {
		return axios.post("/charge/activity/setstatus", data);
	},
	/**
	 * 活动明细
	*/
	getDetail(data) {
		return axios.post("/charge/activity/getdetail", data);
	},
}
/**
 * 积分管理
 */
const PointsManage = {
	/**
	 * 活动管理列表
	*/
	getPointsMallLists(data) {
		return axios.post("/charge/integral/lists", data);
	},
	/**
	 * 获得满足积分商品的优惠券
	*/
	getavailablecoupon(data) {
		return axios.post("/charge/common/getavailablecoupon", data);
	},
	/**
	 * 获得满足积分商品的优惠券
	*/
	addIntegralGoods(data) {
		return axios.post("/charge/integral/addintegralgoods", data);
	},
	/**
	 * 积分奖品详情（兑换订单明细）
	*/
	getIntegralDetail(data) {
		return axios.post("/charge/integral/detail", data);
	},
	/**
	 * 强制下架功能
	*/
	forcedDelisting(data) {
		return axios.post("/charge/integral/soldout", data);
	},
	/**
	 * 积分订单列表
	*/
	getIntegralOrderLists(data) {
		return axios.post("/charge/integral/getorderlist", data);
	},
}
/**
 * 首页轮播
 */
const HomeCarousel = {
	/**
	 * 活动管理列表
	*/
	getBannerLists(data) {
		return axios.post("/charge/banner/list", data);
	},
	/**
	 * 获取电站数据
	*/
	getStationData(data) {
		return axios.post("/charge/banner/get-station-data", data);
	},
	/**
	 * 新增轮播图
	*/
	addCarouselChart(data) {
		return axios.post("/charge/banner/add", data);
	},
	/**
	 * 修改状态
	*/
	editBannerStatus(data) {
		return axios.post("/charge/banner/edit-status", data);
	},
	/**
	 * 删除轮播图
	*/
	deleteBanner(data) {
		return axios.post("/charge/banner/del", data);
	},
}
export {
	FrameApi,
	PowerStations,
	SystemManage,
	Order,
	Member,
	Coupons,
	Home,
	Notification,
	ActivityManage,
	PointsManage,
	HomeCarousel,
};